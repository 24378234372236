import { useDispatch, useSelector } from "react-redux";
import Container from "../../../components/Container";
import { useTranslation } from "react-i18next";
import {
  deleteUser,
  selectPatient,
  updatePatient,
} from "../../../store/account/userSlice";
import { useNavigate } from "react-router-dom";
import { IPatientUpdate } from "../../../interfaces/Patient";
import { notificationPush } from "../../../store/ui/notificationSlice";
import { Button } from "@mui/material";
import { useAuth } from "../../../contexts/Auth";

const DeleteAccountScreen = () => {
  const { data } = useSelector(selectPatient);
  const { currentUser, signOut } = useAuth();
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const privacyPolicyUrl =
    "https://roundtriphealth.com/roundtrip-legal-documents/";

  const updatePatientProfileAndLogout = async (values: IPatientUpdate) => {
    dispatch(updatePatient(values)).catch(() => {
      //display error notification to user
      dispatch(
        notificationPush({
          title: `${t("error")}`,
          message: `${t("error-please-try-again-later")}`,
          type: "error",
        }),
      );
    });
  };

  const handleDeleteAccount = async () => {
    try {
      const deleteRes = await deleteUser(`${currentUser.id}`);

      dispatch(deleteRes);
      await updatePatientProfileAndLogout({
        id: data.id,
        user_id: null,
      });

      await signOut();
      return dispatch(
        notificationPush({
          title: `${t("")}`,
          message: `${t("account-deleted-successfully")}`,
        }),
      );
    } catch {
      dispatch(
        notificationPush({
          title: `${t("error")}`,
          message: `${t("error-please-try-again-later")}`,
          type: "error",
        }),
      );
    }
  };

  const handleKeepAccount = () => navigate(-1);

  return (
    <Container>
      <div
        className="px-8"
        style={{
          alignSelf: "center",
          justifyContent: "center",
          alignContent: "space-between",
        }}>
        <p className="text-sm text-text pt-2">
          By confirming this request, your user account will be permanently
          deleted. However, Roundtrip may still be obligated
          to retain certain information in compliance with applicable laws or
          for legitimate purposes.
        </p>
        <div style={{ flexDirection: "row" }}>
          <p className="text-sm text-text pt-2">
            For additional details, please refer to our&nbsp;
          <a
            style={{ color: "blue", textDecorationLine: "underline" }}
            className="text-sm text-text pt-2"
            href={privacyPolicyUrl}
            target="_blank"
            rel="noopener noreferrer">
            Privacy Policy
          </a>
          </p>
        </div>
        <div style={{ marginTop: 30 }}>
          <Button
            onClick={handleDeleteAccount}
            color="error"
            variant="contained">
            {t("delete-account").toUpperCase()}
          </Button>
          <div style={{ marginTop: 20 }}>
            <Button onClick={handleKeepAccount} variant="text">
              {t("keep-account").toUpperCase()}
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default DeleteAccountScreen;
